@import "../../variables";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.homepage-layout-accordion-list-wrapper {
    padding: 48px 24px;
    gap: 40px;
    background-color: $neutral-10;

    @include media-breakpoint-up(md) {
        padding: 48px 0 74px 0;
        margin: 0 80px;
        max-width: 1400px;
        gap: 0;
    }

    .homepage-layout-accordion-list-buttons {
        gap: 16px;
    }

    .homepage-layout-accordion-list-primary {
        height: 44px;
        padding: 10px 16px;
        color: $white;
        font-family: $font-qualion;
        font-size: 18px;
        font-weight: 700;
        line-height: 26px;
        border-radius: 4px;
        background-color: $dark-green;
        text-decoration: none !important;

        &:hover {
            background-color: $light-green-tertiary;
        }

        @include media-breakpoint-up(md) {
            height: 56px;
            padding: 16px 24px;
        }
    }

    .homepage-layout-accordion-list-secondary {
        height: 44px;
        padding: 10px 16px;
        color: $dark-green;
        font-family: $font-qualion;
        font-size: 18px;
        font-weight: 700;
        line-height: 26px;
        border-radius: 4px;
        border: 2px solid $dark-green;
        text-decoration: none !important;

        &:hover {
            border-color: $light-green-tertiary;
            color: $light-green-tertiary;
            opacity: 0.5;
        }

        @include media-breakpoint-up(md) {
            height: 56px;
            padding: 16px 24px;
        }
    }

    .homepage-layout-accordion-list-container {
        max-width: 100%;

        @include media-breakpoint-up(md) {
            max-width: 679px;
        }
    }

    .homepage-layout-accordion-item-container {
        padding: 16px 0;
        gap: 16px;
        border-bottom: 1px solid $neutral-20;

        &.show {
            .homepage-layout-accordion-description-wrapper {
                display: flex;
            }

            .homepage-layout-accordion-item-icon {
                transform: none;
            }
        }
    }

    .homepage-layout-accordion-description-wrapper {
        display: none;
    }

    .homepage-layout-accordion-item-title {
        color: $dark-green;
        font-family: $font-qualion;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        cursor: pointer;

        @include media-breakpoint-up(md) {
            font-size: 18px;
            line-height: 26px;
        }
    }

    .homepage-layout-accordion-item-description {
        color: $neutral-80;
        font-family: $font-qualion;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;

        @include media-breakpoint-up(md) {
            font-size: 18px;
            line-height: 26px;
        }
    }

    .homepage-layout-accordion-item-icon {
        width: 16px;
        height: 16px;
        color: $dark-green;
        transform: rotate(180deg);
    }

    .homepage-layout-accordion-item-cursor {
        cursor: pointer;
    }
}
